/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-18 16:45:59
 * @LastEditTime: 2021-02-07 17:02:14
 * @FilePath: \acuconference-web\src\mixins\timeFormatFilter.js
 */


import moment from 'moment';
export default {
  methods: {
    toStartTime(time) {
      let startTime = new Date(time)
      let hour = startTime.getHours()
      let minute = startTime.getMinutes()
      let second = startTime.getSeconds()
      time = time - hour * 60 * 60 * 1000
      time = time - minute * 60 * 1000
      time = time - second * 1000
      return time
    },
    toEndTime(time) {
      const unitTime = this.toStartTime(time)
      const endTime = unitTime + 24 * 60 * 60 * 1000 - 1
      return endTime
    },
    formatTime(time) {
      const timeMoment = new moment(time)
      if (this.$vuetify.lang.current === 'th') {
        // buddha hit
        return timeMoment.add(543, 'year').format("DD/MM/YYYY")
      }
      return timeMoment.format("DD/MM/YYYY")
    },
    formatDetailTime(time) {
      const timeMoment = new moment(time)
      if (this.$vuetify.lang.current === 'th') {
        // buddha hit
        return timeMoment.add(543, 'year').format("DD/MM/YYYY HH:mm:ss")
      }
      return timeMoment.format("DD/MM/YYYY HH:mm:ss")
    }
  },
}